<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-subheader>Usuarios</v-subheader>
            <v-data-table
                :headers="headers"
                :items="Users"
                :loading="loadingUsers"
                loading-text="Cargando usuarios..."
                class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Listado de usuarios</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-dialog
                        v-model="dialog"
                        max-width="500px"
                        persistent
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="newUser">
                          Nuevo usuario
                        </v-btn>

                    </template>
                    <v-card>
                      <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form @submit.prevent="createUser">
                              <v-text-field
                                v-model="User.cardId"
                                :error-messages="cardIdErrors"
                                label="Identificación*"
                                required></v-text-field>
                              <v-text-field
                                v-model="User.first_name"
                                :error-messages="first_nameErrors"
                                label="Nombres*"
                                required></v-text-field>
                              <v-text-field
                                v-model="User.last_name"
                                :error-messages="last_nameErrors"
                                label="Apellidos*"
                                required></v-text-field>
                              <v-text-field
                                v-model="User.email"
                                :error-messages="emailErrors"
                                label="Correo electrónico*"
                                required></v-text-field>
                              <v-text-field
                                v-model.number="User.phone"
                                :error-messages="phoneErrors"
                                label="Teléfono*"
                                type="number"
                                required></v-text-field>
                              <v-select
                                v-model="User.townhall_id"
                                :error-messages="townhall_idErrors"
                                :items="townHalls"
                                label="Cliente*"
                                required></v-select>
                          </v-form>
                          <v-alert
                            dense
                            border="left"
                            type="warning"
                            v-model="errorForm"
                          >
                            Los campos con (*) son requeridos.
                          </v-alert>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            text
                            @click="dialog = false"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="createUser()"
                        >
                            Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
              {{item.first_name}} {{item.last_name}}
            </template>
            <template v-slot:item.townhall="{ item }">
              Cliente
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editUser(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:no-data>
              No se encontraron registros
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert';
import { required, email, numeric } from 'vuelidate/lib/validators'

  export default {
    data: () => ({
      dialog: false,
      loadingUsers: true,
      errorForm: false,
      headers: [
        {
          text: 'Nombre completo',
          sortable: false,
          value: 'name',
        },
        { text: 'Cliente', value: 'townhall' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      User: {
        cardId: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        townhall_id: 0,
        expired_date: ''
      },
      Users: [],
      people:[],
      townHalls: [],
      editedIndex: -1,
    }),
    validations: {
      User: {
        cardId: {required},
        first_name: {required},
        last_name: {required},
        email: {required, email},
        phone: {required, numeric},
        townhall_id: {required}
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo usuario' : 'Editar usuario'
      },
      cardIdErrors(){
        const errors = []
        if (!this.$v.User.cardId.$dirty) return errors
        !this.$v.User.cardId.required && errors.push('Este campo es requerido.')
        return errors
      },
      first_nameErrors(){
        const errors = []
        if (!this.$v.User.first_name.$dirty) return errors
        !this.$v.User.first_name.required && errors.push('Este campo es requerido.')
        return errors
      },
      last_nameErrors(){
        const errors = []
        if (!this.$v.User.last_name.$dirty) return errors
        !this.$v.User.last_name.required && errors.push('Este campo es requerido.')
        return errors
      },
      emailErrors(){
        const errors = []
        if (!this.$v.User.email.$dirty) return errors
        !this.$v.User.email.email && errors.push('El correo electrónico debe ser válido.')
        !this.$v.User.email.required && errors.push('Este campo es requerido.')
        return errors
      },
      phoneErrors(){
        const errors = []
        if (!this.$v.User.phone.$dirty) return errors
        !this.$v.User.phone.required && errors.push('Este campo es requerido.')
        !this.$v.User.phone.numeric && errors.push('Este campo debe ser numérico.')
        return errors
      },
      townhall_idErrors(){
        const errors = []
        if (!this.$v.User.townhall_id.$dirty) return errors
        !this.$v.User.townhall_id.required && errors.push('Este campo es requerido.')
        return errors
      },
    },

    mixins:[misMixins],

    methods: {
      newUser(){
        this.User = {
          cardId: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          townhall_id: 0,
          expired_date: ''
        };
        this.dialog = true;
      },

      createUser(){
        this.$v.User.$touch()

        if (!this.$v.User.$invalid) {
          const customActions = {
            create: {headers:{"Authorization": 'Bearer '+this.getCrypto()}, method: 'POST'}    
            //create: {headers:{"Authorization": 'Bearer '+this.getCrypto()}, method: 'POST'}           
          };

          this.resource = this.$resource('create_user',{},customActions);

          this.LoadingOpen();

          this.resource.create({
            data: {
              attributes: {
                password: this.User.cardId,
                email: this.User.email,
                first_name: this.User.first_name,
                last_name: this.User.last_name,
                phone: this.User.phone,
                townhall_id: this.User.townhall_id
              }
            }
          }).then(function(data){
            this.LoadingClose();

            this.SucessAlert(
              'Creado',
              'usuario creado con éxito'
            )
            // Usuario registrado correctamente
            this.getUsers();
            this.dialog = false;

          }).catch(function(error){
            // Error al registrar el usuario
            this.ErrorAlert('Error al crear el usuario')
            console.log('Error: ',error)
            this.dialog = false;
          });
        }else{
          this.errorForm = true;
        }
        
      },
      editUser(user){
        console.log(user)
      },
      getTownhalls(){
        

        //this.$http.get('townhalls',{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
        this.$http.get('townhalls/getTownhalls/'+ this.getUser().user_id)
          .then(response => {
            // Estructurar alcaldías para select
            (response.body).forEach(element => {
              this.townHalls.push({
                text: element.name_townhall,
                value: element.id
              })
            });
            

          }).catch(error => {
            console.log('No fue posible consultar las clientes', error);
          })
      },
      getUsers(){
        this.loadingUsers = true;
        this.Users = [];
        //this.$http.get('clients',{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
        this.$http.get('clients',{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
        .then(response => {
          (response.body).forEach(element => {
            this.Users.push({
              id: element.id,
              first_name: element.first_name,
              last_name: element.last_name,
              townhall: element.townhall_id
            })
          });
          this.loadingUsers = false;
        }).catch(error => {
          console.log('Error al consultar los usuarios', error)
        })
      },
      
      
    },
    beforeMount(){
        this.getTownhalls();
        this.getUsers();
    }
  }
</script>